import * as s from './ArRichCtaWithBg.module.scss'

import React, { useMemo } from 'react'

import { ArButton } from 'src/components/ArButton'
import { ArContainer } from 'src/components/ArContainer'
import { TArRichCtaWithBgProps } from './ArRichCtaWithBg.types'
import classNames from 'classnames'
import { getDeviceValue } from 'src/utils/getDeviceValue'
import { parseRichText } from 'src/utils/parseRichText'
import useDeviceDetect from 'src/utils/useDeviceDetect'

export const ArRichCtaWithBg: React.FC<TArRichCtaWithBgProps> = ({
  actionButtons,
  additionTopElement = undefined,
  backgroundUrl,
  backgroundVideoUrl,
  className,
  classNameSubtitle,
  entryAnimation,
  heights,
  subtitle,
  title,
  withDarkGradient,
  withDarkOverlayer,
}) => {
  const { isMobile } = useDeviceDetect()
  const containerClass = classNames(className, s.container, {
    [s.withDarkGradient]: withDarkGradient,
    [s.withDarkOverlayer]: withDarkOverlayer,
    [s.entryAnimation]: entryAnimation,
  })

  const showImageBg = useMemo(
    () => (backgroundUrl && !backgroundVideoUrl) || isMobile,
    [backgroundUrl, backgroundVideoUrl, isMobile],
  )

  const showVideoBg = useMemo(() => backgroundVideoUrl && !isMobile, [backgroundVideoUrl, isMobile])

  return (
    <div className={s.wrapper}>
      {showImageBg && <div className={s.background} style={{ backgroundImage: `url(${backgroundUrl})` }} />}
      {showVideoBg && (
        <div className={s.backgroundVideo}>
          <video
            muted
            autoPlay
            loop
            playsInline
            poster={backgroundUrl}
            style={{ backgroundImage: `url(${backgroundUrl})`, backgroundSize: 'cover' }}>
            <source src={backgroundVideoUrl} type="video/mp4" />
          </video>
        </div>
      )}
      <ArContainer contentWrapperClassName={containerClass} style={{ height: getDeviceValue(heights, isMobile) }} full>
        {additionTopElement}
        <div className="max-w-4xl">
          <h1 dangerouslySetInnerHTML={{ __html: parseRichText(title) }} />
        </div>
        <div className={`max-w-4xl ${classNameSubtitle}`}>
          <p>{subtitle}</p>
        </div>
        <div className={s.buttons}>
          <div>
            {actionButtons.map(({ title, ...item }, i) => (
              <ArButton
                key={i}
                {...item}
                className={i !== actionButtons.length - 1 ? 'mb-4 md:mb-0 md:mr-8' : undefined}>
                {title}
              </ArButton>
            ))}
          </div>
        </div>
      </ArContainer>
    </div>
  )
}
